import { store } from "@/store/index";
import axios from "axios";
import { DateTime as TimeFormatter } from "luxon";

export const syncStore = {
  namespaced: true,
  state: () => ({
    allSyncedRecords: "",
    syncedRecords: "",
    UnsyncedRecords: { status: false, records: "" },
    downloadedByUser: false
  }),
  getters: {
    getAllUnsyncedExpenseRecords: function() {
      return store.state.expenseStore.allExpense.filter(expense => {
        return !expense.is_synced;
      });
    }
  },
  mutations: {
    getSyncedRecordsFromServer(state) {
      axios
        .get("/expense-tracker-expense/all", {
          headers: {
            Authorization:
              "Bearer " + store.state.authStore.token ||
              store.getters["authStore/getToken"]
          },
          params: { sectionIds: [1, 2, 3, 4, 5] }
        })
        .then(res => {
          state.syncedRecords = res.data;
          store.commit("syncStore/updateAllLocalRecords");
        })
        .catch(err => {
          console.log(err);
        });
    },

    syncAllUnsyncedRecords(state) {
      if (store.getters["syncStore/getAllUnsyncedExpenseRecords"].length > 0) {
        axios
          .post(
            "/expense-tracker-expense/sync",
            {
              data: store.getters["syncStore/getAllUnsyncedExpenseRecords"]
            },
            {
              headers: {
                Authorization:
                  "Bearer " + store.state.authStore.token ||
                  store.getters["authStore/getToken"]
              }
            }
          )
          .then(res => {
            state.syncedRecords = res.data;
            store.commit("syncStore/getSyncedRecordsFromServer");
            store.commit("syncStore/reloadAllLocalRecords");
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    updateAllLocalRecords(state) {
      for (let item of state.syncedRecords) {
        item["_id"] = item["pouch_db_id"];
        item["_rev"] = item["rev"];
        // item["sub_category_id"] = item["sub_category_id"];
        delete item["category_id"];
        delete item["rev"];
        item["type"] = "expense";
        TimeFormatter.fromISO(
          new Date(item["purchase_time"]).toISOString()
        ).toFormat("ff");
        if (item.deleted_at) {
          store.commit("expenseStore/deleteRecord", item);
        } else {
          store.state.database.put(item, { force: true }, function callback(
            err
          ) {
            if (err) {
              console.log(err);
              return false;
            }
          });
        }
      }
      store.commit("syncStore/reloadAllLocalRecords");
    },

    reloadAllLocalRecords() {
      store.commit("expenseStore/getAllExpense");
    },

    userDownloadedFromServer(state, status) {
      state.downloadedByUser = status;
    }
  },
  actions: {
    syncDataAction({ commit }) {
      commit("syncAllUnsyncedRecords");
    }
  }
};
