<template>
  <div class="d-lg-flex d-none t-d-sm-none w-100">
    <div class="container px-0">
      <div class="mr-md-5">
        <b-navbar-brand :to="{ name: 'home' }">
          <img src="img/taxtim-expense-tracker-logo.svg" alt="taxtim-logo" />
        </b-navbar-brand>
      </div>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <menu-items class="d-flex"></menu-items>
          <b-nav-item-dropdown
            right
            menu-class="bg-primary py-0 bl-1"
            id="account"
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>Account</em>
            </template>
            <b-dropdown-item href="#" v-if="!loggedIn" :to="{ name: 'login' }">
              <router-link :to="{ name: 'login' }" tag="span" class="py-2 pl-2"
                >Login</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="$emit('logout')" v-else>
              <span class="py-2 pl-2">Logout</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuItems from "./Menu/MenuItems";

export default {
  name: "DesktopMenu",
  components: {
    MenuItems
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authStore.isAuth
    })
  }
};
</script>

<style>
#account ul.dropdown-menu .dropdown-item:hover {
  background-color: #54d387;
  color: white;
}
#account ul.dropdown-menu .dropdown-item {
  background-color: #007499;
  color: black;
}
</style>
