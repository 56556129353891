<template>
  <div id="app">
    <div
      class="d-flex justify-content-center update-overlay"
      style="align-items: center;"
      v-if="updateInstalled"
    >
      <div class="text-center">
        <h1>Update Successful</h1>
        <img
          src="./assets/tick-circle.svg"
          alt="Check Mark"
          style="width: 100px;"
        />
      </div>
    </div>
    <div
      v-if="updateChecker"
      class="d-flex justify-content-center update-overlay"
      style="align-items: center;"
    >
      <div class="text-center">
        <h1>Update Available</h1>
        <p>Before continuing, a quick app update is need</p>
        <button @click="unregisterServiceWorker" class="btn btn-secondary">
          Allow update
        </button>
      </div>
    </div>
    <loader v-if="loader" :loadingMessage="loadingMessage"></loader>
    <navigation>
      <desktop-menu @logout="logout"></desktop-menu>
      <mobile-menu
        @showLoader="showLoader"
        :menuShown="menuShown"
        @logout="logout"
      ></mobile-menu>
    </navigation>
    <div @click="hideMenu">
      <b-row class="mt-3">
        <b-container v-if="appIsNotInstalled">
          <b-col cols="12" class="text-center">
            <b-alert show variant="light">
              <b-icon icon="exclamation-circle"></b-icon>
              For easy access please consider installing the app
              <button
                class="btn btn-secondary btn-small"
                id="installBtn"
                @click="installPrompt"
              >
                Install
              </button>
            </b-alert>
          </b-col>
        </b-container>
      </b-row>
      <router-view @showLoader="showLoader">
        <div slot="download-confirm-message">
          <transition
            name="alert-slide-fade"
            leave-active-class="animate__animated animate__fadeOutUp"
          >
            <b-alert
              variant="success"
              size="lg"
              show=""
              v-if="downloadingMessage"
              >Downloading records from the cloud</b-alert
            >
          </transition>
        </div>
      </router-view>
      <div style="margin-bottom: 200px"></div>
      <div class="text-center mb-5 d-flex justify-content-center">
        <span v-if="!updateChecker" class="mb-5 text-disable py-2 pr-2">
          Latest
        </span>
        <span class="mb-5 text-disable py-2 pr-2">Version 1.0.12</span>
        <div v-if="updateChecker">
          <button
            @click="unregisterServiceWorker"
            class="btn btn-primary text-white"
          >
            Update available
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/partials/Navigation";
import DesktopMenu from "@/components/partials/DesktopMenu";
import MobileMenu from "@/components/partials/MobileMenu";
import { mapGetters, mapState } from "vuex";
import Loader from "./components/partials/Loader";
import { register } from "register-service-worker";

export default {
  name: "App",
  components: {
    Navigation,
    DesktopMenu,
    MobileMenu,
    Loader
  },
  data() {
    return {
      menuShown: false,
      loader: false,
      updateChecker: false,
      loadingMessage: "",
      downloadingMessage: false,
      updateInstalled: false,
      appIsNotInstalled: false
    };
  },
  async created() {
    // check if app is installed
    try {
      const relatedApps = await navigator.getInstalledRelatedApps();
      this.appIsNotInstalled = relatedApps.length < 1;

      //checking for App update
      this.updateInstalled = !!localStorage.getItem("updateInstalled");
      if (process.env.NODE_ENV === "production") {
        var vm = this;
        register(`${process.env.BASE_URL}service-worker.js`, {
          updated() {
            vm.updateChecker = true;
            console.log("New content has been installed; please refresh.");
          }
        });
      }

      let defferedPrompt;
      const addbtn = document.querySelector("#installBtn");

      window.addEventListener("beforeinstallprompt", event => {
        event.preventDefault();
        defferedPrompt = event;
        addbtn.style.display = "block";
      });

      addbtn.addEventListener("click", () => {
        defferedPrompt.prompt();
        console.log("install prompt");
        defferedPrompt.userChoice.then(choice => {
          if (choice.outcome === "accepted") {
            console.log("user accepted the prompt");
          }
          defferedPrompt = null;
        });
      });
    } catch (error) {
      console.log(error);
    }
    //install prompt for PWA


    this.$store.state.database.createIndex({
      index: { fields: ["type", "_id", "is_synced"] }
    });

    //sidemenu toggle
    this.$root.$on("sideMenuIsVisible", () => {
      this.menuShown = true;
    });

    this.$store.commit("checkFirstTimeOpening");
    this.$store.commit("authStore/isLoggedIn");

    setTimeout(() => {
      if (localStorage.getItem("updateInstalled")) {
        this.updateInstalled = false;
        localStorage.removeItem("updateInstalled");
      }
    }, 3000);
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authStore.isAuth,
      // unSyncedRecords: state => state.syncStore.allUnsyncedExpenseRecords
      userDownloadedFromServer: state => state.syncStore.downloadedByUser
    }),
    ...mapGetters({
      unSyncedRecords: "syncStore/getAllUnsyncedExpenseRecords"
    })
  },
  watch: {
    unSyncedRecords() {
      setTimeout(() => {
        this.syncExpense();
      }, 5000);
    },
    userDownloadedFromServer() {
      this.downloadingMessage = true;
      var vm = this;
      setTimeout(() => {
        vm.downloadingMessage = false;
      }, 10000);
    }
  },
  methods: {
    //The methods below are all for the report
    hideMenu: function() {
      this.menuShown = false;
    },
    showLoader: function(args) {
      this.loader = args[0];
      this.loadingMessage = args[1];
    },
    logout: function() {
      this.$store.commit("authStore/logout");
      document.location.reload();
    },
    syncExpense: function() {
      if (this.unSyncedRecords.length > 0 && this.loggedIn) {
        setInterval(() => {
          if (window.navigator.onLine) {
            this.$store.dispatch("syncStore/syncDataAction");
          }
        }, 10000);
      }
    },
    unregisterServiceWorker: function() {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
        localStorage.setItem("updateInstalled", "true");
        document.location.reload();
      });
    },
    installPrompt: function() {
      console.log("install prompt");
      let defferedPrompt;
      const addbtn = document.querySelector("#installBtn");

      window.addEventListener("beforeinstallprompt", event => {
        event.preventDefault();
        defferedPrompt = event;
        addbtn.style.display = "block";
      });

      addbtn.addEventListener("click", () => {
        defferedPrompt.prompt();

        defferedPrompt.userChoice.then(choice => {
          if (choice.outcome === "accepted") {
            console.log("user accepted the prompt");
          }
          defferedPrompt = null;
        });
      });
    }
  }
};
</script>
<style>
.mobile-spacing {
  margin-top: 30%;
}

#account ul.dropdown-menu .dropdown-item {
  background-color: #007499;
  color: #fff;
}

.update-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.96);
  z-index: 2;
  cursor: pointer;
}
</style>
