import { store } from "@/store/index";
import axios from "axios";

export const authStore = {
  namespaced: true,
  state: () => ({
    isAuth: false,
    token: null,
    loginError: "",
    setCookieHeaders: ""
  }),
  getters: {
    getToken() {
      // return   state.token;
      return localStorage.getItem("jwt") || null;
    }
  },
  mutations: {
    logIn(state, formData) {
      state.loginError = false;
      axios
        .post(
          "login_check",
          {
            username: formData.email,
            password: formData.password
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(res => {
          state.isAuth = true;
          store.commit("authStore/setTokenBearer", res.data.token);
          localStorage.setItem("email", formData.email);

          //checking unsynced data
          store.commit("syncStore/getSyncedRecordsFromServer");
        })
        .catch(err => {
          state.loginError = true;
          console.log(err);
        });
    },
    setTokenBearer(state, token) {
      state.token = token;
      localStorage.setItem("jwt", state.token);
    },

    isLoggedIn(state) {
      //verify that the server is happy with the token
      if (store.getters["authStore/getToken"]) {
        axios
          .get("/expense-tracker-expense/is_auth", {
            headers: {
              Authorization: "Bearer " + store.getters["authStore/getToken"]
            }
          })
          .then(() => {
            state.token = store.getters["authStore/getToken"];
            state.isAuth = true;
            //get records from server
            store.commit("syncStore/getSyncedRecordsFromServer");
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    logout(state) {
      localStorage.clear();
      state.isAuth = false;
      state.token = "";
      store.state.database.destroy().catch(err => {
        console.log(err);
      });
      store.commit("syncStore/reloadAllLocalRecords");
    }
  },
  actions: {}
};
