<template>
  <div>
    <div class="container">
      <b-row class="row justify-content-center">
        <b-col class="col-md-10">
          <div>
            <transition
              name="alert-slide-fade"
              leave-active-class="animate__animated animate__fadeOutUp"
            >
              <b-alert variant="success" size="lg" show="" v-if="success"
                >New expense saved successfully</b-alert
              >
            </transition>
            <transition
              name="alert-slide-fade"
              leave-active-class="animate__animated animate__fadeOutUp"
            >
              <b-alert variant="danger" size="lg" show="" v-if="fail"
                >There was a problem saving your expense. Please try
                again</b-alert
              >
            </transition>
          </div>

          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-body title="Save a new expense" class="text-left">
                  <validation-observer v-slot="{ invalid }" ref="observer">
                    <b-form>
                      <b-form-group
                        id="input-group-1"
                        label="Category"
                        label-for="input-1"
                        description="Pick a category here..."
                      >
                        <validation-provider
                          :rules="'required'"
                          v-slot="{ errors }"
                        >
                          <select
                            class="custom-select"
                            name=""
                            v-model="expense.category_id"
                            required
                          >
                            <option
                              v-for="cat in categories"
                              :value="cat.category_id"
                              :key="cat._id"
                              >{{ cat.title }}</option
                            >
                          </select>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        id="input-group-2"
                        label="Date"
                        label-for="input-2"
                      >
                        <validation-provider
                          :rules="'required'"
                          v-slot="{ errors }"
                        >
                          <datetime
                            type="datetime"
                            :format="'ff'"
                            v-model="expense.date"
                            :input-class="{ 'form-control': true }"
                            required
                          >
                          </datetime>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        id="input-group-3"
                        label="Title"
                        label-for="input-3"
                      >
                        <validation-provider
                          :rules="'required'"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            id="input-3"
                            v-model="expense.title"
                          ></b-form-input>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        id="input-group-4"
                        label="Cost"
                        label-for="input-3"
                      >
                        <validation-provider
                          :rules="'required|integer'"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            id="input-3"
                            type="number"
                            v-model="expense.cost"
                            required
                          ></b-form-input>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group>
                        <b-img
                          v-show="false"
                          src="#"
                          id="img"
                          fluid
                          alt="Fluid image"
                          :type="imgType"
                          ref="img"
                          @load="resizeImg(600)"
                        ></b-img>
                        <input
                          type="file"
                          accept="image/x-png,image/jpeg"
                          ref="input"
                          class="form-control"
                          @change="takePhoto($event)"
                        />
                        <div class="pt-5">
                          <b-img
                            src="https://plchldr.co/i/500x250"
                            fluid
                            alt="Fluid image"
                            ref="new"
                          ></b-img>
                        </div>
                      </b-form-group>
                      <b-button
                        size="lg"
                        variant="primary"
                        @click="saveExpense"
                        :disabled="invalid"
                        >Save</b-button
                      >
                    </b-form>
                  </validation-observer>
                </b-card-body>
              </b-col>
              <b-col
                md="6"
                class="d-lg-flex justify-content-center border-left"
                :class="{ 'bg-primary': false, 'd-none': false }"
              >
                <b-card-body class="mb-3 text-left" title="Recent Expense">
                  <list-of-saved-expense
                    :limit="true"
                    @changeEditedExpense="updateEditedExpense"
                  ></list-of-saved-expense>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import ListOfSavedExpense from "@/components/partials/ListOfSavedExpense";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { integer, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("integer", {
  ...integer,
  message: "Please input numbers only"
});

export default {
  name: "NewExpense",
  components: {
    Datetime,
    ListOfSavedExpense,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      newExpense: {
        title: "",
        category_id: "",
        date: new Date().toISOString(),
        saved_at: new Date().toISOString(),
        cost: "",
        _id: new Date().toISOString(),
        type: "expense",
        file_encoded: "",
        is_synced: false
      },
      newEditedExpense: "",
      img: "",
      imgType: ""
    };
  },
  props: {
    editableExpense: Object
  },
  created() {
    var vm = this;
    this.$on("load", val => {
      vm.$refs.new.src = val;
    });
  },
  methods: {
    saveExpense: function() {
      this.$store.commit("expenseStore/saveExpense", this.expense);
      this.resetForm();
      this.$refs.observer.reset();
    },
    resetForm: function() {
      this.expense.title = "";
      this.expense.cost = "";
      this.expense.date = new Date().toISOString();
      this.expense.saved_at = new Date().toISOString();
      this.expense.cost = "";
      this.expense._id = new Date().toISOString();
      this.expense.type = "expense";
      this.expense.file_encoded = "";
      this.expense.category_id = "";
      this.$refs.input.value = "";
      this.$refs.img.src = "";
      this.$refs.new.src = "https://plchldr.co/i/500x250";
    },
    takePhoto() {
      // var imgFile = new Image(); //create a image
      var vm = this;
      let reader = new FileReader();
      reader.onload = function() {
        vm.$refs.img.src = reader.result;
      };
      reader.readAsDataURL(this.$refs.input.files[0]);
      // this.expense.fileBlob = this.$refs.input.files[0];
      this.imgType = vm.$refs.input.files[0].type;
    },

    resizeImg(width) {
      let img = document.createElement("img");
      img.src = this.$refs.img.src;
      let canva = document.createElement("canvas");
      if (img.naturalWidth < 600) {
        alert("image is too small");
        return false;
      }
      //    console.log("I am running");
      let ctx = canva.getContext("2d");

      img.width = img.naturalWidth;
      img.height = img.naturalHeight;
      // alert(img.naturalWidth + "x" + img.naturalHeight);
      canva.width = width;
      let scaleFactor = width / img.width;
      canva.height = img.height * scaleFactor;

      ctx.drawImage(img, 1, 1, canva.width, canva.height);
      let imgType = this.$refs.img.type + ";charset=UTF-8";
      let encodedImg = canva.toDataURL(imgType);

      this.$refs.new.src = encodedImg;
      // this.$refs.img.style.display = "none";
      this.expense.file_encoded = encodedImg;
      this.img = encodedImg;
    },
    updateEditedExpense: function(val) {
      this.showImg(val.file_encoded);
      this.newEditedExpense = val;
    },
    showImg: function(val) {
      this.$emit("load", val);
    }
  },
  computed: {
    ...mapGetters({
      categories: "catStore/getSelectedCategories"
    }),

    ...mapState({
      fail: state => state.expenseStore.fail,
      success: state => state.expenseStore.success
    }),

    expense: function() {
      if (this.newEditedExpense) {
        return this.newEditedExpense;
      } else if ("editableExpense" in this.$route.params) {
        this.showImg(this.editableExpense.file_encoded);
        return this.editableExpense;
      } else {
        return this.newExpense;
      }
    }
  },
  watch: {
    expense: function(val) {
      if ("editableExpense" in this.$route.params || this.newEditedExpense) {
        this.showImg(val.file_encoded);
      }
    }
  },
  beforeDestroy() {
    this.newEditedExpense = "";
  }
};
</script>

<style scoped></style>
