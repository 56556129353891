<template>
  <b-modal
    id="new-expense-modal"
    hide-footer
    hide-header
    ref="newExpenseModal"
    size="lg"
    body-bg-variant="secondary"
    body-text-variant="white"
    @hidden="$emit('hideNewExpenseModal', 'hide')"
  >
    <div class="container-fluid">
      <b-row class="row justify-content-center">
        <b-col>
          <div>
            <h2 class="text-center" style="padding: 20px 0;">New Expense</h2>
          </div>
          <validation-observer v-slot="{ invalid }" ref="observer">
            <b-form>
              <ValidationObserver>
                <b-form-group id="input-group-01" label-for="input-1">
                  <validation-provider
                    :rules="'required'"
                    vid="incomeType"
                    v-slot="{ errors }"
                  >
                    <select
                      class="custom-select tt-border-radius"
                      name=""
                      v-model="incomeTypeId"
                      @change="showSelectedSectionCategories"
                      id="income-type"
                      required
                    >
                      <option :selected="!expense.sub_category_id" value=""
                        >Income Type</option
                      >
                      <option
                        v-for="income in incomeTypes"
                        :value="income.id"
                        :key="income.id"
                        >{{ income.name }}</option
                      >
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
                <b-form-group id="input-group-1" label-for="input-group-1">
                  <validation-provider
                    :rules="'required'"
                    v-slot="{ errors }"
                    vid="expenseType"
                  >
                    <select
                      class="custom-select tt-border-radius"
                      name=""
                      v-model="expense.sub_category_id"
                      required
                    >
                      <option value="">Select expense type</option>
                      <option
                        v-for="(cat, index) in selectedSectionCategories"
                        :value="cat.id"
                        :key="index"
                        :selected="
                          editableExpense
                            ? editableExpense.sub_category_id === cat.id
                            : false
                        "
                        >{{ cat.name }}</option
                      >
                    </select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
                <b-form-group id="input-group-3" label-for="input-3">
                  <validation-provider
                    :rules="'required|is_not_internet:@expenseType,@incomeType'"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      id="input-3"
                      v-model="expense.title"
                      placeholder="Title of your expense"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </ValidationObserver>
              <b-form-group
                id="input-group-2"
                label="Date / Time"
                label-for="input-2"
              >
                <validation-provider :rules="'required'" v-slot="{ errors }">
                  <datetime
                    type="date"
                    v-model="expense.purchase_time"
                    :input-class="{ 'form-control': true }"
                    required
                  >
                  </datetime>
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
              <b-form-group id="input-group-4" label-for="input-3">
                <validation-provider
                  :rules="'required|is_not_numeric'"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    id="input-3"
                    v-model="expense.cost"
                    required
                    placeholder="Enter amount"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-img
                  v-show="false"
                  src="#"
                  id="img"
                  fluid
                  alt="Fluid image"
                  :type="imgType"
                  ref="img"
                  @load="resizeImg(600)"
                ></b-img>
                <label for="img"> Upload image</label>
                <validation-provider
                  :rules="'image'"
                  v-slot="{ errors }"
                  ref="isImage"
                >
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    class="form-control p-1"
                    @change="takePhoto($event)"
                    ref="input"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
                <div class="pt-3" style="text-align: center;">
                  <b-img
                    :src="
                      expense.image_path
                        ? 'https://expense.taxtim.com/' + expense.image_path
                        : expense.file_encoded
                    "
                    fluid
                    ref="new"
                  ></b-img>
                </div>
              </b-form-group>
              <b-button
                class="mt-3 text-white"
                block
                variant="success"
                @click="saveExpense"
                :disabled="invalid"
                >Save</b-button
              >
              <b-form-group>
                <b-button
                  class="mt-3 text-white"
                  block
                  variant="warning"
                  @click="$emit('hideNewExpenseModal', 'hide')"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { numeric, required, image, double } from "vee-validate/dist/rules";
import sha1 from "js-sha1";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("numeric", {
  ...numeric,
  message: "Please input numbers only"
});
extend("double", {
  ...double,
  message: "Please input numbers only"
});
extend("image", {
  ...image,
  message: "Please upload images only"
});

extend("is_not_internet", {
  validate(value, { incomeType, expenseType }) {
    //TODO something is off here so this is a quick fix for now
    let income = expenseType;
    let expense = incomeType;
    if (income === 1 && expense === 3) {
      let words = value.split(" ");
      return words.indexOf("internet") === -1;
    }
    return true;
  },
  message: "'unfortunately you cannot enter the word 'internet'",
  params: ["incomeType", "expenseType"]
});

extend("is_not_numeric", {
  validate(value) {
    return isFinite(value);
  },
  message: "'please enter a number'"
});

export default {
  name: "NewExpenseModal",
  components: {
    Datetime,
    ValidationObserver,
    ValidationProvider
  },
  created() {
    var vm = this;
    this.expense.hash = sha1(new Date().toISOString() + Math.random());
    this.$on("load", val => {
      vm.$refs.new.src = val;
    });
  },
  props: {
    newExpenseModalState: {
      required: true
    },
    editableExpense: { type: Object, required: true }
  },
  data() {
    return {
      newExpense: {
        title: "",
        sub_category_id: "",
        income_id: "",
        date: new Date().toISOString(),
        purchase_time: new Date().toISOString(),
        cost: "",
        hash: "",
        _id: new Date(
          +new Date() - Math.floor(Math.random() * 10000000000)
        ).toISOString(),
        type: "expense",
        file_encoded: "",
        is_synced: false
      },
      selectedSectionCategories: "",
      incomeTypeId: "",
      newEditedExpense: "",
      img: "",
      imgType: ""
    };
  },
  methods: {
    saveExpense: function() {
      this.$store.commit("expenseStore/saveExpense", this.expense);
      this.resetForm();
      this.$refs.observer.reset();
      this.$emit("hideNewExpenseModal", "hide");
    },
    resetForm: function() {
      this.expense.title = "";
      this.expense.cost = "";
      this.expense.hash = sha1(new Date().toISOString() + Math.random());
      this.expense.date = new Date().toISOString();
      this.expense.cost = "";
      this.expense._id = new Date(
        +new Date() - Math.floor(Math.random() * 10000000000)
      ).toISOString();
      this.expense.type = "expense";
      this.expense.file_encoded = "";
      this.expense.sub_category_id = "";
      this.income_id = "";
      this.$refs.input.value = "";
      this.$refs.img.src = "";
      this.$refs.new.src = "https://plchldr.co/i/500x250";
    },

    async takePhoto(e) {
      // var imgFile = new Image(); //create a image
      var vm = this;
      const { valid } = await this.$refs.isImage.validate(e);
      if (!valid) {
        return false;
      }
      console.log(this.$refs.input.files);
      let reader = new FileReader();
      reader.onload = function() {
        vm.$refs.img.src = reader.result;
      };
      reader.readAsDataURL(this.$refs.input.files[0]);
      // this.expense.fileBlob = this.$refs.input.files[0];
      this.imgType = vm.$refs.input.files[0].type;
    },
    showSelectedSectionCategories: function() {
      this.selectedSectionCategories = this.allSubCategories.filter(item => {
        return item && item.category_id === parseInt(this.incomeTypeId);
      });

      return this.sortSelectedSectionCategories(this.selectedSectionCategories);
    },
    sortSelectedSectionCategories: function(values) {
      let items = values;
      items.sort(function(a, b) {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return items;
    },
    resizeImg(width) {
      let img = document.createElement("img");
      img.src = this.$refs.img.src;
      let canva = document.createElement("canvas");
      if (img.naturalWidth < 600) {
        alert("image is too small");
        return false;
      }
      //    console.log("I am running");
      let ctx = canva.getContext("2d");

      img.width = img.naturalWidth;
      img.height = img.naturalHeight;
      // alert(img.naturalWidth + "x" + img.naturalHeight);
      canva.width = width;
      let scaleFactor = width / img.width;
      canva.height = img.height * scaleFactor;

      ctx.drawImage(img, 1, 1, canva.width, canva.height);
      let imgType = this.$refs.img.type + ";charset=UTF-8";
      let encodedImg = canva.toDataURL(imgType);

      this.$refs.new.src = encodedImg;
      // this.$refs.img.style.display = "none";
      this.expense.file_encoded = encodedImg;
      this.img = encodedImg;
    },
    updateEditedExpense: function(val) {
      this.showImg(val.file_encoded);
      this.newEditedExpense = val;
    },
    showImg: function(val) {
      this.$emit("load", val);
    },
    setIsSyncedToFalsy: function() {
      // eslint-disable-next-line vue/no-mutating-props
      this.editableExpense.is_synced = false;
    }
  },
  computed: {
    ...mapState({ allCategories: state => state.catStore.allCategories }),
    ...mapGetters({
      CategoriesIdNamePair: "catStore/getAllCategoriesObjById",
      allSubCategories: "catStore/getAllSubCategoriesObjById",
      incomeTypes: "catStore/getAllCategoriesObjById"
    }),
    expense: function() {
      if (!this.editableExpense.title) {
        return this.newExpense;
      }
      this.setIsSyncedToFalsy();
      return this.editableExpense;
    }
  },
  watch: {
    newExpenseModalState: function(value) {
      value === "show"
        ? this.$refs.newExpenseModal.show()
        : this.$refs.newExpenseModal.hide();
    },
    incomeTypeId: function(value) {
      this.expense.income_id = value;
    }
  },
  beforeDestroy() {
    this.newEditedExpense = "";
  }
};
</script>

<style scoped>
#new-expense-modal {
  top: 66px;
}
</style>
