<template>
  <b-row class="mt-3">
    <b-container v-if="!loggedIn">
      <b-col cols="12" class="text-center">
        <b-alert show variant="warning">
          <b-icon icon="exclamation-circle"></b-icon>
          To view, export and back up your expenses
          <router-link
            :to="{ name: 'login' }"
            class="font-weight-bold"
            tag="span"
          >
            log in here
          </router-link>
        </b-alert>
      </b-col>
    </b-container>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BannerMessage",
  computed: {
    ...mapState({
      loggedIn: state => state.authStore.isAuth
    })
  }
};
</script>

<style scoped></style>
