import { store } from "@/store/index";
// import predifinedCategories from "@/categoriesObj";

export const reportStore = {
  namespaced: true,
  state: () => ({
    currentYearExpense: [],
    currentYear: new Date().getFullYear(),
    startMonth: 2, //March as `JS counts month from 0..
    startDate: "",
    currentDate: new Date(),
  }),
  getters: {
    getStartDate: function(state) {
      let lastYear = state.currentYear - 1;
      let assumedStartDate = new Date(state.currentYear, state.startMonth);
      return state.currentDate < assumedStartDate
        ? new Date(lastYear, state.startMonth)
        : assumedStartDate;
    },
    getCurrentYearExpenses: function() {
      // let expenses = [];
      //  console.log(store.state.expenseStore.allExpense);
      return store.getters["expenseStore/getActiveExpense"].filter(item => {
        if (
          new Date(item.time).getFullYear() ===
            store.getters["reportStore/getStartDate"].getFullYear() &&
          item.deleted_at
        ) {
          return item;
        }
      });

      // return expenses;
    },
    getArrOfSelectedCatWithExpenseCounter: function() {
      let catObj = {};
      store.getters["catStore/getAllSubCategoriesObjById"].forEach(item => {
        // Y stands for the Y axis used in the   high-charts library
        // Yaxis is used to store the number expenses selected for one category
        //This is used on the pie chart
        catObj[item.id] = {
          name:
            item.name +
            " - " +
            store.getters["catStore/getAllCategoriesObjById"][item.category_id]
              .name,
          y: 0
        };
      });

      return catObj;
    }
  },

  mutations: {
    setCurrentDate(state, date) {
      state.currentDate = date;
    }
  },
  actions: {}
};
