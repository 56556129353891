<template>
  <div class="d-lg-none d-md-flex justify-content-between w-100">
    <span>
      <b-navbar-toggle
        target="sidebar"
        @click="showMenu"
        style="vertical-align: top;"
        class="border-0 pl-0  "
      ></b-navbar-toggle>
      <b-navbar-brand :to="{ name: 'home' }" class="pb-0 mr-0" id="logo">
        <!--        TaxTim logbook-->
        <img
          src="img/taxtim-expense-tracker-logo.svg"
          alt=""
          width="150px"
          class="pb-0 d-md-inline"
        />
        <div class="mt-n2 d-none">
          <small class="text-white text-left pl-2 d-block">TaxTim</small>
          <p class="text-white text-left pl-2 mt-n2">Expense Tracker</p>
        </div>
      </b-navbar-brand>
    </span>
    <b-button-group class="text-right pl-3" v-if="auth" id="mobile-btn">
      <b-button variant="outline-info">
        <b-avatar
          icon="person"
          badge
          :badge-variant="status"
          size="1.5em"
        ></b-avatar>
      </b-button>
      <b-button variant="outline-info" @click="getDataFromServer">
        <b-icon icon="cloud-download"></b-icon>
      </b-button>
    </b-button-group>
    <b-sidebar
      id="sidebar-header"
      header-class="text-white"
      aria-labelledby="sidebar-header-title"
      header
      shadow
      bg-variant="secondary"
      width="250px"
      class="d-flex"
      v-model="drawerMenuState"
    >
      <template ref="nav">
        <div class="pt-2">
          <b-nav vertical>
            <b-col cols="12" class="px-0">
              <menu-items></menu-items>
              <b-nav-item class="py-0">
                <div>
                  <b-dropdown-divider></b-dropdown-divider>
                </div>
              </b-nav-item>

              <b-nav-item class="pl-0 pt-0">
                <b-dropdown
                  toggle-class="nav-link pl-0 font-weight-bold"
                  text="Accounts"
                  variant="transparent"
                  style="background-color: transparent"
                >
                  <b-dropdown-item
                    v-if="!auth"
                    style="background-color: transparent"
                    :to="{ name: 'login' }"
                  >
                    <router-link
                      :to="{ name: 'login' }"
                      class="pl-1 border-0 text-white"
                      >Login</router-link
                    >
                  </b-dropdown-item>
                  <b-dropdown-item
                    class="bg-active-transparent"
                    @click="$emit('logout')"
                    v-else
                  >
                    <span class="pl-1 text-white">Logout</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav-item>
            </b-col>
          </b-nav>
        </div>
      </template>
    </b-sidebar>
    <login-form-modal></login-form-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoginFormModal from "./LoginFormModal";
import MenuItems from "./Menu/MenuItems";

export default {
  name: "MobileMenu",
  components: {
    LoginFormModal,
    MenuItems
  },
  data() {
    return {
      drawerMenuState: false,
      loginModalState: "hidden"
    };
  },
  props: {
    menuShown: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    showMenu() {
      this.$root.$emit("sideMenuIsVisible");
      this.drawerMenuState = true;
    },
    getDataFromServer() {
      this.$emit("showLoader", [true, "Downloading your expenses..."]);
      this.$store.commit("syncStore/getSyncedRecordsFromServer");
      this.$store.commit("syncStore/userDownloadedFromServer", [true]);
      this.$store.commit("syncStore/reloadAllLocalRecords");
      var vm = this;
      setTimeout(function() {
        vm.$emit("showLoader", [false, "Downloading your expenses..."]);
      }, 5000);
    }
  },
  computed: {
    status: function(value) {
      return value ? "success" : "warning";
    },

    ...mapState({
      auth: state => state.authStore.isAuth
    })
  },
  watch: {
    menuShown: function(val) {
      if (!val) {
        this.drawerMenuState = false;
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 765px) {
  #mobile-btn {
    right: 7%;
    position: absolute;
  }
}
@media (min-width: 900px) {
  #logo {
    display: none;
  }
}

@media (max-width: 325px) {
  #mobile-btn {
    right: 1%;
    position: absolute;
  }
}
ul.dropdown-menu {
  background-color: transparent !important;
}
</style>
<style>
ul.dropdown-menu {
  background-color: transparent !important;
  border: 0 !important;
}
ul.dropdown-menu .dropdown-item {
  padding-left: 3px;
}

.b-sidebar > .b-sidebar-header .close {
  float: none;
  font-size: 1.5rem;
  color: #eee !important;
}
</style>
