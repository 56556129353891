<template>
  <div>
    <b-nav-item :to="{ name: 'expense' }" tag="span">Expenses </b-nav-item>

    <b-nav-item :to="{ name: 'stats' }">
      Summary and stats
    </b-nav-item>
    <b-nav-item :to="{ name: 'about' }">
      Help
    </b-nav-item>
    <!--    <b-nav-item to="how-it-works" tag="span">How To </b-nav-item>-->
  </div>
</template>

<script>
export default {
  name: "MenuItems"
};
</script>

<style scoped></style>
