<template>
  <div>
    <banner-message></banner-message>
    <div class="container">
      <b-row class="row justify-content-center">
        <b-col class="col-md-12">
          <div class="mb-5 text-center">
            <div class="mb-5">
              <h1>About This App</h1>
            </div>
            <hr class="mb-1" />
            <about-app></about-app>
          </div>
          <category-drop-down class="mb-1"></category-drop-down>
          <b-list-group-item
            v-b-toggle.accordion-3
            class="d-flex justify-content-between align-items-center bg-white tt-border-radius mb-1 p-0"
          >
            <span class="p-1" role="tab">
              <b-button block variant="transparent"
                ><a
                  href="https://www.taxtim.com/za/help/ask-a-tax-question?ask=expert"
                  target="_blank"
                >
                  Have any questions?</a
                ></b-button
              >
            </span>
          </b-list-group-item>
          <b-collapse id="accordion-3" class="mt-n1 w-100 text-left">
            <a
              class="pl-3 pt-3"
              href="https://www.taxtim.com/za/help/ask-a-tax-question?ask=expert"
              target="_blank"
              >Our tax professionals are available to answer your questions</a
            >
          </b-collapse>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CategoryDropDown from "../components/partials/CategoryDropDown";
import AboutApp from "../components/partials/AboutApp";
import BannerMessage from "../components/partials/BannerMessage";
export default {
  name: "Settings",
  components: {
    CategoryDropDown,
    BannerMessage,
    AboutApp
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
