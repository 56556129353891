import Vue from "vue";
import Vuex from "vuex";
import PouchDB from "pouchdb";
import plugin from "pouchdb-find";
import { categoryStore } from "@/store/category";
import { expenseStore } from "@/store/expense";
import { reportStore } from "@/store/report";
import { authStore } from "@/store/auth";
import { syncStore } from "@/store/sync";

Vue.use(Vuex);
PouchDB.plugin(plugin);

export const store = new Vuex.Store({
  modules: {
    catStore: categoryStore,
    expenseStore: expenseStore,
    reportStore: reportStore,
    authStore: authStore,
    syncStore: syncStore
  },
  state: {
    database: new PouchDB("expenseBZ23"),
    fail: "",
    success: ""
  },
  mutations: {
    //happens on first time using the app
    appInitialisation() {
      store.commit("catStore/seedAllCategories");
    },

    getAllData() {
      store.commit("expenseStore/getAllExpense");
      store.commit("catStore/getAllCategories");
    },
    checkFirstTimeOpening(state) {
      state.database
        .find({
          selector: { type: "category", _id: { $exists: true } },
          sort: ["_id"]
        })
        .then(result => {
          result.docs.length > 0
            ? store.commit("getAllData")
            : store.commit("appInitialisation");
        })
        .catch(function(err) {
          console.log(err);
        });
    },

    getRecordByType(state, type) {
      state.database
        .find({
          selector: { type: type, _id: { $exists: true } },
          sort: ["_id"]
        })
        .then(result => {
          result.docs.reverse();
        })
        .catch(function(err) {
          console.log(err);
        });
    },

    setSuccesStatus(state) {
      state.success = true;
    },
    setFailedStatus(state) {
      state.fail = true;
    },
    clearMes(state) {
      setTimeout(() => {
        state.success = "";
        state.fail = "";
      }, 2000);
      return true;
    }
  },
  actions: {
    setSuccesStatusAction({ commit }) {
      commit("setSuccesStatus");
      commit("clearMes");
    },
    setFailedStatusAction({ commit }) {
      commit("setFailedStatus");
      commit("clearMes");
    }
  }
});
