import { store } from "@/store/index";
// import predifinedCategories from "@/categoriesObj";

export const expenseStore = {
  namespaced: true,
  state: () => ({
    allExpense: [],
    currentYearExpense: [],
    fail: "",
    success: ""
  }),
  getters: {
    getAllExpense(state) {
      return state.allExpense;
    },

    //non deleted expense
    getActiveExpense: function(state) {
      return state.allExpense.filter(expense => {
        return !expense.deleted_at;
      });
    }
  },
  mutations: {
    saveExpense: function(state, data) {
      store.state.database.put(data, { force: true }, function callback(err) {
        if (err) {
          store.dispatch("expenseStore/setFailedStatusAction", "save");
          console.log(err);
          return false;
        }
        store.dispatch("expenseStore/setSuccesStatusAction", "save");
        store.commit("expenseStore/getAllExpense");
      });
    },

    getAllExpense: function(state) {
      store.state.database
        .find({
          selector: {
            type: "expense",
            _id: { $exists: true }
          },
          sort: ["_id"]
        })
        .then(result => {
          state.allExpense = result.docs;
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    setSuccesStatus(state) {
      state.success = true;
    },
    setFailedStatus(state) {
      state.fail = true;
    },

    clearMes(state) {
      setTimeout(() => {
        state.success = "";
        state.fail = "";
      }, 2000);
      return true;
    },

    //mark record for deletion on pouch
    setRecordForDeletion(state, data) {
      data["deleted_at"] = new Date().toISOString();
      data["is_synced"] = false;
      store.state.database.put(data, function callback(err) {
        if (err) {
          store.dispatch("setFailedStatusAction", "save");
          console.log(err);
          return false;
        }
        // store.commit("deleteRecord");
        store.dispatch("setSuccesStatusAction", "save");
        store.commit("expenseStore/getAllExpense");
      });
    },

    //delete record from Pouch
    deleteRecord(state, item) {
      store.state.database;
      item.is_synced = true;
      item._deleted = true;
      store.state.database.put(item, { force: true }, function callback(err) {
        if (err) {
          console.log(err);
          return false;
        }
        store.commit("expenseStore/getAllExpense");
      });
    }
  },
  actions: {
    setSuccesStatusAction({ commit }) {
      commit("setSuccesStatus");
      commit("clearMes");
    },
    setFailedStatusAction({ commit }) {
      commit("setFailedStatus");
      commit("clearMes");
    }
  }
};
