import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "@/store/index";

// import Home from "../views/Home.vue";
import Settings from "@/views/Setting";
import Login from "@/views/Login";
import Expense from "@/views/Expense";
import NewExpense from "@/views/NewExpense";
import Stats from "../views/Stats";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Expense
  },
  {
    path: "/about",
    name: "about",
    component: Settings
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.state.authStore.isAuth) {
        next({ name: "add-expense" });
      }
      next();
    }
  },
  {
    path: "/expenses",
    name: "expense",
    component: Expense,
    props: true
  },
  {
    path: "/edit-expense",
    name: "edit-expense",
    component: NewExpense,
    props: true,
    beforeEnter: (to, from, next) => {
      from.name === "add-expense" || from.name === "expense"
        ? next()
        : next({ name: "expense" });
    }
  },
  {
    path: "/stats",
    name: "stats",
    component: Stats
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
