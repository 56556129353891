<template>
  <div class="bg-secondary">
    <div class="container">
      <b-navbar toggleable="lg" type="dark" variant="secondary" class="px-md-0">
        <slot></slot>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation"
};
</script>

<style scoped>
.navbar-expand-lg {
  justify-content: space-between;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
</style>
