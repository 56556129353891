<template>
  <div class="container">
    <b-row class="row justify-content-center">
      <b-col class="col-md-12">
        <div class="my-5">
          <h2 class="text-center">Your Login</h2>
        </div>
        <div class="py-2">
          <div class="alert alert-info text-center">
            To backup your data, login here
          </div>
        </div>
        <div class="py-2" v-if="error">
          <div class="alert alert-danger text-center">
            Can't login, please make sure your email/password are correct
          </div>
        </div>
        <validation-observer v-slot="{ invalid }" ref="observer">
          <b-form method="post" action="#" @submit.prevent="login">
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              label="Email"
              label-class="pl-2"
            >
              <validation-provider
                :rules="'required|email'"
                v-slot="{ errors }"
              >
                <b-form-input
                  id="input-1"
                  type="email"
                  v-model="form.email"
                  placeholder="Enter email"
                  required
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label-for="input-2"
              label="Password"
              label-class="pl-2"
            >
              <b-form-input
                id="input-1"
                type="password"
                v-model="form.password"
                placeholder="Password"
                required
              ></b-form-input>
              <div class="text-right">
                <a
                  href="https://www.taxtim.com/za/forgot-password"
                  class="text-primary pr-1"
                  >Forgot password?</a
                >
              </div>
              <div class="mt-4">
                <b-button
                  type="submit"
                  class="mt-3 text-white"
                  block
                  variant="success"
                  :disabled="invalid"
                  >Let's Start</b-button
                >
              </div>
            </b-form-group>
            <div class="text-center">
              <span
                >To backup and sync your data with the cloud, login with your
                TaxTim account below. Don't have a TaxTim account?
                <a
                  href="https://www.taxtim.com/za/get-started?register"
                  class="text-primary"
                  >Register here.</a
                >
              </span>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("email", {
  ...email,
  message: "Please input a valid email address"
});
export default {
  name: "Login",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    login: function() {
      this.$emit("showLoader", [true, "Logging You in"]);
      var vm = this;
      setTimeout(() => {
        vm.$store.commit("authStore/logIn", this.form);
        vm.$emit("showLoader", [false, "Logging You in"]);
      }, 1000);
    }
  },

  computed: {
    sreenSize: function() {
      return window.innerWidth;
    },
    ...mapState({
      loggedIn: state => state.authStore.isAuth,
      error: state => state.authStore.loginError
    })
  },

  watch: {
    loggedIn: function(value) {
      if (value) {
        this.$router.push({ name: "expense" });
      }
    }
  }
};
</script>

<style scoped></style>
