<template>
  <div>
    <select
      name="categories"
      id=""
      class="form-control  tt-border-radius"
      v-model="currentSelectedCatObj"
    >
      <option value="">See what I can claim</option>
      <option
        v-for="category in CategoriesIdNamePair"
        :value="category"
        :key="category.id"
        :selected="category.id > 4 ? 'selected' : ''"
      >
        {{ category.name }}</option
      >
    </select>
    <div v-if="CategoriesIdNamePair[currentSelectedCatObj.id]">
      <b-list-group-item
        variant="secondary"
        v-show="true"
        class="d-flex justify-content-center align-items-center bg-secondary text-white tt-border-radius mb-1 p-3"
        v-b-toggle="
          'collapse-' + CategoriesIdNamePair[currentSelectedCatObj.id]
        "
      >
        <span>{{ CategoriesIdNamePair[currentSelectedCatObj.id].name }} </span>
        <!--        <span>-->
        <!--          <b-icon-->
        <!--            icon="chevron-double-down"-->
        <!--            size="1"-->
        <!--            class="text-right"-->
        <!--          ></b-icon>-->
        <!--        </span>-->
      </b-list-group-item>
      <b-form-checkbox-group switches id="checkbox-group-2">
        <div v-for="cat in subCategoryList" :key="cat.id">
          <b-collapse
            visible="visible"
            :id="
              'collapse-' + CategoriesIdNamePair[currentSelectedCatObj.id].id
            "
            class="rounded w-100 mb-1"
          >
            <b-list-group-item
              variant="transparent"
              class="d-flex justify-content-between align-items-center"
            >
              <span v-b-toggle="'collapse-inner-' + cat.hash">
                {{ cat.name }}
              </span>
              <span v-b-toggle="'collapse-inner-' + cat.hash">
                <b-icon
                  icon="chevron-down"
                  size="1"
                  class="text-right"
                ></b-icon>
              </span>
            </b-list-group-item>
          </b-collapse>
          <b-collapse :id="'collapse-inner-' + cat.hash" class="mt-n1 w-100">
            <b-card bg-variant="white" body-class="p-0">
              <b-card-body>
                <p class="mts-0 text" v-html="cat.description"></p>
              </b-card-body>
            </b-card>
          </b-collapse>
        </div>
      </b-form-checkbox-group>
    </div>
  </div>
</template>

<style>
#checkbox-group-2 .text span {
  display: block;
  padding-left: 14px;
}
</style>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CategoryDropDown",
  components: {},
  data() {
    return {
      options: [],
      selectedCat: [],
      currentSelectedCat: "",
      currentSelectedCatObj: "",
      isChecked: true
    };
  },
  methods: {
    editedCat: function($event, category) {
      if ($event.target.checked) {
        category.selected = true;
        category.is_synced = false;
        this.$store.dispatch("catStore/saveSelectedCatAction", category);
      } else {
        this.$store.dispatch("catStore/removeSelectedCatAction", category);
      }
    }
  },
  computed: {
    ...mapState({ allCategories: state => state.catStore.allCategories }),
    ...mapGetters({
      CategoriesIdNamePair: "catStore/getAllCategoriesObjById",
      allSubCategories: "catStore/getAllSubCategoriesObjById"
    }),

    subCategoryList: function() {
      return this.allSubCategories.filter(item => {
        return item.category_id === this.currentSelectedCatObj.id;
      });
    }
  }
};
</script>
