import "core-js/stable";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { store } from "./store/index";
import axios from "axios";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "./app.scss";

Vue.config.productionTip = false;
axios.defaults.baseURL = "https://www.taxtim.com/api";
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
