<template>
  <div class="accordion" role="tablist">
    <b-list-group-item
      v-b-toggle.accordion-1
      class="d-flex justify-content-between align-items-center bg-white tt-border-radius mb-1 p-0"
    >
      <span class="p-3 text-black" role="tab">
        How to use this app
      </span>
    </b-list-group-item>
    <b-collapse id="accordion-1" class="mt-n1 w-100">
      <b-card bg-variant="transparent">
        <b-card-body>
          <p>
            The TaxTim Expense Tracker is easy and simple to use. Your journey
            starts on the Your Expenses page where you can record expenses by
            pressing the "Add a new expense" button, pick the applicable income
            type and then the expense type, give it a title, a date, the amount
            spent, and optionally a picture of the receipt. Click “Save" and you
            are done, your expense is saved.
          </p>
          <p>
            Please note, that you will need to log in for this information to be
            saved on your TaxTim account(on the cloud), this will allow you to
            view it on different devices and not lose it. After saving, you can
            view the stats of all expenses that you have saved. The Expenses
            Stats page offers a pie chart for you to visually see all expenses
            saved for a selected month and a year.
          </p>
        </b-card-body>
      </b-card>
    </b-collapse>
    <b-list-group-item
      v-b-toggle.accordion-2
      class="d-flex justify-content-between align-items-center bg-white tt-border-radius mb-1 p-0"
    >
      <span class="p-3" role="tab">
        How expenses affect your tax return
      </span>
    </b-list-group-item>
    <b-collapse id="accordion-2" class="mt-n1 w-100">
      <b-card bg-variant="transparent">
        <b-card-body>
          It is very important to keep track of your business expenses for tax
          purposes. Tax is payable on your 'net income’ or ‘profit', which is
          calculated by subtracting your business expenses from your business
          income. This means that the higher your business expenses, the less
          will be the profit, and therefore the less tax you will pay! You need
          to keep an accurate record of your business expenses as you will need
          to submit these details to SARS. If you can’t provide the proof (i.e a
          list of expenses with supporting invoices/receipts), SARS will
          disallow your expense claim and you will have to pay more tax.
        </b-card-body>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "AboutApp"
};
</script>

<style>
#checkbox-group-2 .nav-link {
  color: #6392c5 !important;
}
</style>
