import { store } from "@/store/index";
import axios from "axios";

export const categoryStore = {
  namespaced: true,
  state: () => ({
    allCategories: [],
    predifinedCategories: [],
    randomTimeIsoFormat: new Date().toISOString()
  }),
  getters: {
    getAllCategoriesObjById: function(state) {
      let sectionCategoryObj = {};
      state.allCategories.map(item => {
        sectionCategoryObj[item.id] = item;
      });

      return sectionCategoryObj;
    },

    getAllSubCategoriesObjById: function(state) {
      let subCategoryObj = [];
      state.allCategories.map(category => {
        category.subcategories.map(item => {
          subCategoryObj[item.id] = item;
        });
      });
      return subCategoryObj;
    }
  },
  mutations: {
    seedCategory: function(state, data) {
      store.state.database.put(data, { force: true }, function callback(err) {
        if (err) {
          console.log(err);
          return false;
        }
      });
    },

    getAllCategories: function(state) {
      store.state.database
        .find({
          selector: { type: "category", _id: { $exists: true } },
          sort: ["_id"]
        })
        .then(result => {
          state.allCategories = result.docs;
        })
        .catch(function(err) {
          console.log(err);
        });
    },

    seedAllCategories: function(state) {
      axios
        .get("/expense-tracker-expense/category")
        .then(res => {
          //get records from server
          state.predifinedCategories = res.data;
          res.data.map(item => {
            store.commit("catStore/getRandIsoTimeStamp"),
              (item["type"] = "category");
            item["_id"] = state.randomTimeIsoFormat;
            store.commit("catStore/seedCategory", item);
            store.commit("catStore/getAllCategories");
          });
        })
        .catch(err => {
          console.log(err);
        });
      // store.commit("catStore/seedCategory", predifinedCategories.version);
      store.commit("catStore/getAllCategories");
    },
    saveSelectedCat: function(state, cat) {
      store.state.database.put(cat, function callback(err) {
        if (err) {
          return false;
        }
      });
    },

    removeSelectedCat: function(state, cat) {
      cat.selected = false;
      cat.is_synced = false;
      store.state.database.put(cat, { force: true }, function callback(err) {
        if (err) {
          console.log(err);
          return false;
        }
        store.commit("catStore/getAllCategories");
      });
    },
    getRandIsoTimeStamp: function(state) {
      var date = new Date();
      let start = new Date(
        date.getFullYear() - 1,
        date.getMonth(),
        date.getDate()
      );
      state.randomTimeIsoFormat = new Date(
        start.getTime() + Math.random() * (date.getTime() - start.getTime())
      );
    }
  },
  actions: {
    saveSelectedCatAction({ commit }, cat) {
      commit("saveSelectedCat", cat);
    },
    removeSelectedCatAction({ commit }, cat) {
      commit("removeSelectedCat", cat);
    }
  }
};
