<template>
  <div>
    <banner-message></banner-message>
    <div class="container">
      <b-row class="justify-content-center">
        <div class="col-md-10">
          <div>
            <transition
              name="alert-slide-fade"
              leave-active-class="animate__animated animate__fadeOutUp"
            >
              <b-alert variant="success" size="lg" show="" v-if="success"
                >New expense saved successfully</b-alert
              >
            </transition>
            <transition
              name="alert-slide-fade"
              leave-active-class="animate__animated animate__fadeOutUp"
            >
              <b-alert variant="danger" size="lg" show="" v-if="fail"
                >There was a problem saving your expense. Please try
                again</b-alert
              >
            </transition>
            <slot name="download-confirm-message">
              <transition
                name="alert-slide-fade"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <b-alert variant="success" size="lg" show="" v-if="success"
                  >New expense saved successfully</b-alert
                >
              </transition>
            </slot>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="mb-3">
            <h1 class="mb-4 text-center">Expenses</h1>
          </div>
        </div>
        <b-col class="col-md-12">
          <list-of-saved-expense
            :limit="false"
            @showEditingExpense="editingExpense"
          >
            <b-col class="px-0">
              <hr class="mb-4" />
              <b-button
                variant="success"
                class="w-100 py-2 btn-new text-white mb-3 tt-border-radius"
                @click="newExpenseModalState = 'show'"
                >Add a new expense</b-button
              >
            </b-col>
          </list-of-saved-expense>
          <new-expense-modal
            :editableExpense="editableExpense"
            :newExpenseModalState="newExpenseModalState"
            @hideNewExpenseModal="hideNewExpenseModal"
            :key="componentKey"
          ></new-expense-modal>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ListOfSavedExpense from "@/components/partials/ListOfSavedExpense";
import NewExpenseModal from "../components/partials/NewExpenseModal";
// import moment from "moment";
import { mapGetters, mapState } from "vuex";
import BannerMessage from "../components/partials/BannerMessage";
export default {
  name: "Expense",
  components: {
    ListOfSavedExpense,
    NewExpenseModal,
    BannerMessage
  },
  data() {
    return {
      newExpenseModalState: "hide",
      editableExpense: {},
      componentKey: 0
    };
  },
  methods: {
    editingExpense: function(value) {
      this.editableExpense = value[1];
      this.newExpenseModalState = value[0];
    },
    hideNewExpenseModal: function() {
      this.newExpenseModalState = "hide";
      this.editableExpense = {};
      this.forceNewExpenseModalComponentUpdate();
    },
    forceNewExpenseModalComponentUpdate: function() {
      this.componentKey += 1;
    }
  },
  computed: {
    ...mapState({
      // saveRecord: state => state.expenseStore.allExpense,
      loggedIn: state => state.authStore.isAuth,
      fail: state => state.expenseStore.fail,
      success: state => state.expenseStore.success
    }),
    ...mapGetters({
      selectedCategoriesWithSavedExpenseCounter:
        "reportStore/getArrOfSelectedCatWithExpenseCounter",
      seasonDateStart: "reportStore/getStartDate"
      // selectedSectionCategories: "catStore/getSelectedCategories"
    })
  }
};
</script>
