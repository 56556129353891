<template>
  <div class="overlay">
    <div class="gooey">
      <div class="text">{{ loadingMessage }}</div>
      <span class="dot"></span>
      <div class="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loadingMessage: { required: true }
  }
};
</script>

<style scoped>
body {
  background-color: #00f;
}
.text {
  margin-left: 10px;
  width: 100%;
  height: 20px;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.9);
}
.gooey {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 20px;
  margin: -20px 0 0 -71px;
  background-color: transparent;
  filter: contrast(20);
  z-index: 1200;
}
.dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 32px;
  left: 15px;
  filter: blur(0px);
  background: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}
.dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(0px);
  background: #000;
  border-radius: 50%;
}
@-moz-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-webkit-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-o-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-moz-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@-webkit-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@-o-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
</style>
