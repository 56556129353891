<template>
  <b-modal
    id="new-expense-modal"
    hide-footer
    hide-header
    ref="loginModal"
    size="lg"
    body-bg-variant="secondary"
    body-text-variant="white"
  >
    <div class="container-fluid">
      <b-row class="row justify-content-center">
        <b-col>
          <div class="my-2">
            <h2 class="text-center">Login</h2>
          </div>
          <validation-observer v-slot="{ invalid }" ref="observer">
            <b-form method="post" action="#" @submit.prevent="login">
              <b-form-group
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  type="email"
                  v-model="form.email"
                  placeholder="Enter email"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Password"
                label-for="input-2"
              >
                <b-form-input
                  id="input-1"
                  type="password"
                  v-model="form.password"
                  placeholder="Password"
                  required
                ></b-form-input>
                <div class="mt-4">
                  <b-button
                    type="submit"
                    class="text-white"
                    variant="primary"
                    :disabled="invalid"
                    >Login</b-button
                  >
                </div>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "LoginFormModal",
  watch: {
    loginModalState: function(value) {
      value === "show"
        ? this.$refs.loginModal.show()
        : this.$refs.loginModal.hide();
    }
  }
};
</script>

<style scoped></style>
