<template>
  <div>
    <transition
      name="alert-slide-fade"
      leave-active-class="animate__animated animate__fadeOutUp"
    >
      <b-alert variant="success" size="lg" show="" v-if="success"
        >New expense deleted successfully</b-alert
      >
    </transition>
    <transition
      name="alert-slide-fade"
      leave-active-class="animate__animated animate__fadeOutUp"
    >
      <b-alert variant="danger" size="lg" show="" v-if="fail"
        >There was a problem deleting your expense. Please try again</b-alert
      >
    </transition>
    <slot> </slot>
    <b-list-group v-if="currentExpensesOnPage.length > 0">
      <b-list-group-item
        style="background-color: #B2D5E0"
        class="text-white tt-border-radius border mb-1 mt-2"
      >
        Saved Expenses
      </b-list-group-item>
      <template v-if="currentExpensesOnPage.length > 0">
        <b-list-group-item
          v-for="expense in currentExpensesOnPage"
          :key="expense._id"
          ref="list"
          class="tt-border-radius mb-1"
        >
          <div v-if="!expense.deleted_at">
            <div class="row" @click="selectedExpense = expense">
              <div class="col-6" style="font-size: .87em;" v-b-modal.img>
                {{ expense.title }}
              </div>
              <div class="col-6 col-sm-4 offset-sm-2 mt-n2 pr-0">
                <div class="d-flex justify-content-around">
                  <b-avatar
                    class="mr-0"
                    alt="here"
                    :src="
                      expense.file_encoded
                        ? expense.file_encoded
                        : 'https://expense.taxtim.com/' + expense.image_path
                    "
                    icon="camera"
                    variant="transparent"
                    v-b-modal.img
                  ></b-avatar>
                  <span class="mt-2" @click="editExpense(expense)">
                    <b-icon
                      alt="here"
                      scale="1.3"
                      icon="pencil"
                      variant="transparent"
                    ></b-icon>
                  </span>
                  <span class="mr-0 align-middle rounded-circle b-avatar">
                    <b-icon
                      alt="here"
                      scale="1"
                      icon="x-circle-fill"
                      variant="danger"
                      v-b-modal.delete
                    ></b-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-n3" v-if="allSubCategories[expense.sub_category_id]">
              <small>{{
                allSubCategories[expense.sub_category_id].name
              }}</small>
            </div>
            <div class="d-flex justify-content-between">
              <span class="text-secondary">{{
                expense.cost | formatMoney | currencySymbol
              }}</span>
              <span>
                <small>{{ formatDate(expense.purchase_time) }}</small>
              </span>
            </div>
          </div>
        </b-list-group-item>
      </template>
      <template v-else>
        <p class="text-center">No saved expenses to show yet</p>
      </template>
      <div class="container" v-if="allExpenses.length > 0">
        <nav
          aria-label="Page navigation example"
          class="row justify-content-center"
        >
          <ul class="pagination justify-content-between col-12 col-md-8 px-0">
            <li class="page-item disabled">
              <b-button
                type="button"
                class="bg-transparent border-0"
                :disabled="currentPage === 1"
                @click="currentPage--"
              >
                <b-icon icon="chevron-left" size="1" class="text-left"></b-icon>
              </b-button>
            </li>
            <li class="page-item" v-for="x in numberOfPage" :key="x">
              <span :class="{ 'text-primary': currentPage === x }">{{
                x
              }}</span>
            </li>
            <li class="page-item">
              <b-button
                type="button"
                class="bg-transparent border-0"
                :disabled="currentPage === numberOfPage"
                @click="currentPage++"
              >
                <b-icon
                  icon="chevron-right"
                  size="1"
                  class="text-right"
                ></b-icon>
              </b-button>
            </li>
          </ul>
        </nav>
      </div>
    </b-list-group>

    <b-modal id="delete" centered title="Delete" header-bg-variant="primary">
      <template>
        <p>Are you sure about this action ?</p>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="tt-button"
          @click="
            deleteExpense();
            ok();
          "
        >
          Delete
        </b-button>
        <b-button variant="primary" class="text-white" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="img"
      centered
      header-bg-variant="secondary"
      header-close-variant="white"
      body-bg-variant="transparent"
      content-class="bg-transparent"
      hide-footer
      body-class="p-0"
      header-border-variant="transparent"
    >
      <div>
        <img
          :src="
            (selectedExpense.file_encoded
              ? selectedExpense.file_encoded
              : 'https://expense.taxtim.com/' + selectedExpense.image_path) ||
              'https://plchldr.co/i/500x250'
          "
          alt="image"
          width="100%"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "ListOfSavedExpense",
  data() {
    return {
      selectedExpense: "",
      itemsPerPage: 10,
      currentPage: 1
    };
  },
  props: {
    limit: Boolean
  },
  computed: {
    ...mapState({
      fail: state => state.fail,
      success: state => state.success
    }),
    ...mapGetters({
      allSubCategories: "catStore/getAllSubCategoriesObjById",
      allExpenses: "expenseStore/getActiveExpense"
    }),
    numberOfPage: function() {
      return Math.ceil(this.allExpenses.length / this.itemsPerPage);
    },
    currentExpensesOnPage: function() {
      let startFrom = this.currentPage * this.itemsPerPage - this.itemsPerPage;
      let itemsEnd = this.currentPage * this.itemsPerPage;
      return this.allExpenses.slice(startFrom, itemsEnd);
    }
  },
  methods: {
    formatDate: function(date) {
      return moment(date).format("ddd D MMM YYYY");
    },
    deleteExpense: function() {
      this.$store.commit(
        "expenseStore/setRecordForDeletion",
        this.selectedExpense
      );
    },
    editExpense: function(expense) {
      // expense.
      this.$emit("showEditingExpense", ["show", expense]);
    }
  },
  filters: {
    formatMoney: function(
      amount,
      decimalCount = 2,
      decimal = ".",
      thousands = ","
    ) {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    },
    currencySymbol(value) {
      return "R " + value;
    }
  }
};
</script>

<style scoped>
.page-link {
  background-color: #f5f6f9;
  border: 0;
  color: #000000;
}

.page-item .btn {
  color: black;
}
</style>
